import React from "react";
import Footer from "../../layouts/website/Footer";
import Header from "../../layouts/website/Header";
import InvestmentSteps from "../../layouts/website/publication/InvestmentSteps";
import InvestorPremeable from "../../layouts/website/publication/InvestorPremeable";
import MainHeader from "../../layouts/website/publication/MainHeader";
import Head from "../../_helpers/Head";


export default function Events () {
    return (
        <>
            <div class="flex flex-col space-y-20 ...">
                <Head title="Events" />
                <Header />
                <MainHeader />
            </div>
            <InvestorPremeable />
            <InvestmentSteps />
            <Footer />

        </>
    );
}

// import { useState } from 'react';
// // import SecondaryHeader from './SecondaryHeader';
// import { Dialog } from '@headlessui/react';
// import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
// import Logo from '../../_helpers/Logo';
// import SecondaryHeader from '../../layouts/website/SecondaryHeader';

// const navigation = [
//   { name: 'Home', href: '/' },
//   { name: 'Membership', href: 'membership' },
//   { name: 'Exchange Operations', href: 'operations' },
//   { name: 'Market Data', href: 'market-data' },
//   { name: 'Investors', href: 'invest' },
//   { name: 'Technology', href: 'technology' },
//   { name: 'About Us', href: 'about-us' },
//   { name: 'Contact', href: 'contact-us' },
//   { name: 'Publications', href: 'publications' },
// ];

// export default function PublicationsPage() {
//   const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

//   return (
//     <div className="bg-white">
//       <header className="absolute inset-x-0 top-0 z-50">
//         <SecondaryHeader />
//         <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
//           <div className="flex lg:flex-1">
//             <a href="../../../assets/images/warehousereceipt.png" className="-m-1.5 p-1.5">
//               <span className="sr-only">ZAMACE</span>
//               <Logo />
//             </a>
//           </div>
//           <div className="hidden lg:flex lg:gap-x-12">
//             {navigation.map((item) => (
//               <a key={item.name} href={item.href} className="text-lg font-semibold leading-6 text-gray-900">
//                 {item.name}
//               </a>
//             ))}
//           </div>
//         </nav>
//       </header>

//       <main className="relative isolate px-6 pt-32 lg:px-8">
//         <div className="mx-auto max-w-2xl text-center">
//           <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
//             Publications
//           </h1>
//           <p className="mt-6 text-lg leading-8 text-gray-600">
//             Explore our latest reports, articles, and insights.
//           </p>
//         </div>
//         <div className="mt-10 w-full mx-auto max-w-5xl">
//           <div className="relative w-full overflow-hidden">
//             <div className="flex transition-transform duration-700 ease-in-out">
//               <img src="/images/publication1.jpg" alt="Publication 1" className="w-full" />
//               <img src="/images/publication2.jpg" alt="Publication 2" className="w-full" />
//               <img src="/images/publication3.jpg" alt="Publication 3" className="w-full" />
//             </div>
//           </div>
//         </div>
//       </main>
//     </div>
//   );
// }
