import { BookmarkIcon } from '@heroicons/react/20/solid'
import one from '../../../assets/images/zamace photos/one.jpg'
import two from '../../../assets/images/zamace photos/two.jpg'
import six from '../../../assets/images/zamace photos/six.jpg'
import PRESS_RELEASE from '../../../assets/images/zamace photos/PRESS_RELEASE.jpg'

const AFCFTA = [
    {
        description:
            "Victoria Falls, Zimbabwe, 27 March 2025 – African trade reaches new heights with the signing of the Memorandum of Understanding (MoU) under the umbrella of the African Continental Free Trade Area (AfCFTA) Association of Commodity Exchanges (A-ACX) on 26 March 2025, at the second A-ACX ",
        icon: BookmarkIcon,
    },
    
    {
        description: "This achievement brings together four commodity exchanges in Eastern and Southern Africa: The Agricultural Commodity Exchange for Africa (ACE Africa) in Malawi, the Tanzania Mercantile Exchange (TMX), the Zambian Agricultural Commodity Exchanges (ZAMACE), and the Zimbabwe Mercantile Exchange (ZMX). The MoU shows a commitment of the exchanges to establish a common framework for collaboration, while they will maintain the respective operational independence. ",
        icon: BookmarkIcon,
    },
    {
        
        description: "This is a significant step towards boosting regional trade, leveraging each commodity exchange’s expertise to foster collaboration and boost market efficiency. The A-ACX will also work towards improving price discovery, increasing market liquidity, creating broader opportunities for farmers and traders, boosting cross-border trade in Eastern and Southern Africa, and reducing price volatility. Beyond market dynamics, it will contribute to food security across the region. ",
        icon: BookmarkIcon,
    },
]

const kenya = [
    {
        description:
            'The Zambian Commodity Exchange (ZAMACE) who is an associate member of the Association of Futures Markets (AFM) proudly joined industry leaders at the 27th Annual (AFM) Conference in Nairobi, Kenya, marking a significant step in strengthening Zambia’s role in local, regional and global commodity trading..',
        icon: BookmarkIcon,
    },
    {
        
        description: "The AFM Conference, which brings together commodity exchanges, financial experts, and policy-makers, is a key platform for shaping the future of trading, investment, and market regulations. With Africa's commodities markets rapidly evolving, the conference presents an invaluable opportunity for ZAMACE to forge new partnerships and drive growth in Zambia’s agricultural and financial sectors.",
        icon: BookmarkIcon,
    },
    {
        description: 'A more integrated and global market will significantly benefit the Zambian economy by increasing agricultural exports, promoting economic diversification, and enhancing market stability. In alignment with the national vision, ZAMACE is committed to supporting Zambia’s goal of producing 1.0M MT metric tonnes of wheat, 1.0M MT of soya beans and 10.0M MT of maize by 2027 therefore facilitating a thriving agricultural sector with a vibrant commodity market and safe storage for grain which will, in turn, create employment opportunities, empower local farmers, and elevate the quality of produce available for export.',
        icon: BookmarkIcon,
    },
    {
        description: 'As ZAMACE continues to engage with global stakeholders, its efforts remain focused on positioning Zambia as a competitive player in the international commodities market.',
        icon: BookmarkIcon,
    },
]


export default function InvestmentSteps() {
    return (
        <div className="overflow-hidden bg-white py-24 sm:py-32">
            {/* Top Section */}
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="lg:flex lg:items-start lg:justify-between">
                    {/* Left Content */}
                    <div className="lg:w-1/2 lg:pr-8 lg:pt-4">
                        <div className="lg:max-w-lg">
                            <h2 className="text-base font-semibold leading-7 text-green-600">Latest</h2>
                            <p className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-xl">
                                EASTERN AND SOUTHERN AFRICAN COMMODITY EXCHANGES SIGN MOU DURING THE AFCFTA 
                                ASSOCIATION OF COMMODITY EXCHANGES ANNUAL MEETING AT VICTORIA FALLS, ZIMBABWE.
                            </p>
                            <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                                {AFCFTA.map((feature) => (
                                    <div key={AFCFTA.description} className="relative pl-9">
                                        <feature.icon className="absolute left-1 top-1 h-5 w-5 text-green-600" aria-hidden="true" />
                                        <dd className="inline">{feature.description}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>

                    {/* Right Content */}
                    <div className="lg:w-1/2 flex flex-col items-center lg:items">
                        <img
                            src={PRESS_RELEASE}
                            alt="press release"
                            className="w-full max-w-xs rounded-xl shadow-xl ring-1 ring-gray-400/10"
                            width={432}
                            height={242}
                        />
                        <a href="./Press Release AfCFTA.pdf" download className="justify-center mt-2 text-sm text-grey-300 underline">
                            Click to download full Document
                        </a>
                    </div>
                </div>
            </div>

            <hr className="mt-16 border-gray-200 mx-auto max-w-7xl px-6 lg:px-8" />

            {/* ZAMACE IN KENYA Section */}
            <div className="mx-auto max-w-7xl px-6 lg:px-8 mt-16">
                <div className="lg:flex lg:items-start lg:justify-between">
                    {/* Left Content */}
                    <div className="lg:w-1/2 lg:pr-8 lg:pt-4">
                        <div className="lg:max-w-lg">
                           
                            <p className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                                ZAMACE IN KENYA
                            </p>
                            <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                                {kenya.map((feature) => (
                                    <div key={feature.description} className="relative pl-9">
                                        <feature.icon className="absolute left-1 top-1 h-5 w-5 text-green-600" aria-hidden="true" />
                                        <dd className="inline">{feature.description}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>

                    {/* Right Content */}
                    <div className="lg:w-1/2 flex flex-col items-center lg:items gap-8">
                        <img
                            src={one}
                            alt="Investor"
                            className="w-full max-w-xs rounded-xl shadow-xl ring-1 ring-gray-400/10"
                            width={432}
                            height={242}
                        />
                        <img
                            src={six}
                            alt="Investor"
                            className="w-full max-w-xs rounded-xl shadow-xl ring-1 ring-gray-400/10"
                            width={432}
                            height={242}
                        />
                    </div>
                </div>
            </div>

            <hr className="mt-16 border-gray-200 mx-auto max-w-7xl px-6 lg:px-8" />

            {/* Upcoming Events */}
            <div className="mx-auto max-w-7xl px-6 lg:px-8 mt-16">
                <div className="lg:max-w-lg">
                    <h2 className="text-base font-semibold leading-7 text-green-600">Stay Tuned</h2>
                    <p className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">Upcoming Events</p>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                        We will post our upcoming events..
                    </p>
                </div>
            </div>
        </div>
    )
}