import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import two from '../../../assets/images/zamace photos/two.jpg';
import three from '../../../assets/images/zamace photos/three.jpg';
import four from '../../../assets/images/zamace photos/four.jpg';
import five from '../../../assets/images/zamace photos/five.jpg';
import six from '../../../assets/images/zamace photos/six.jpg';
import seven from '../../../assets/images/zamace photos/seven.jpg';
import eight from '../../../assets/images/zamace photos/eight.jpg';
import nine from '../../../assets/images/zamace photos/nine.jpg';
import ten from '../../../assets/images/zamace photos/ten.jpg';
import eleven from '../../../assets/images/zamace photos/eleven.jpg';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default function InvestorPremeable() {
  const images = [ two , three, four, five, six, seven, eight, nine,ten, eleven]

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
        <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
          <h2 className="mt-2 text-3xl tracking-tight font-bold text-gray-900 dark:text-white">
            ZAMACE latest activities
          </h2>
          <br />
          <p className="mt-6">
             {/* ZAMACE is a transparent market place for the trade of commodities, certification of storage sites, grading and valuation for stored commodities under warehouse receipts, as well as an enabler for the financial sector to finance warehouse receipts. */}
          </p>
        </div>
        <div className="swiper-container mt-2 w-full">
          <Swiper
            modules={[Navigation, Pagination, Autoplay]} 
            spaceBetween={30}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            className="rounded-lg"
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  className="w-full h-96 object-cover rounded-lg"
                  src={image}
                  alt={`soybeans ${index + 1}`}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}
