import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';

import Frontpage from './layouts/website/Frontpage';
import { history } from './_helpers/history';
import { Nav } from './_components/Nav';
import { PrivateRoute } from './_components/PrivateRoute';
import { Home } from './pages/dashboard/home';
import Membership from './pages/website/Membership';
import Company from './pages/website/Company';
import MarketData from './pages/website/MarketData';
import Investor from './pages/website/Investor';
import Technology from './pages/website/Technology';
import Operations from './pages/website/Operations';
import Contact from './pages/website/Contact';
import Register from './pages/dashboard/register/Register';
//import Login from './pages/dashboard/login/Login';
import PrivacyPolicy from './pages/website/PrivicyPolicy';
import TermsConditions from './pages/website/TermsConditions';
import Brokers from './pages/website/Brokers';
import Warehouses from './pages/website/Warehouses';
import Events from './pages/website/Events';

function App () {

    history.navigate = useNavigate();
    history.location = useLocation();

    return (
        <>
            <Nav />
            <Routes>
                <Route
                    path="/dashboard"
                    element={
                        <PrivateRoute>
                            <Home />
                        </PrivateRoute>
                    }
                />
                <Route path="/" element={<Frontpage />} />
                <Route path="/membership" element={<Membership />} />
                <Route path="/brokers" element={<Brokers />} />
                <Route path="/warehouses" element={<Warehouses />} />
                <Route path="/about-us" element={<Company />} />
                <Route path="/market-data" element={<MarketData />} />
                <Route path="/invest" element={<Investor />} />
                <Route path="/technology" element={<Technology />} />
                <Route path="/operations" element={<Operations />} />
                <Route path="/register" element={<Register />} />
                <Route path="/contact-us" element={<Contact />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/Events" element={<Events />} />
                <Route path="/terms-conditions" element={<TermsConditions />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </>
    );
}

export default App;
