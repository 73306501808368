import React from "react";
import MainContent from "../../layouts/website/data/MainContent";
import MainHeader from "../../layouts/website/data/MainHeader";
import Footer from "../../layouts/website/Footer";
import Header from "../../layouts/website/Header";
import Head from "../../_helpers/Head";
import MarketDataTabs from "../../layouts/website/data/MarketDataTabs";

export default function MarketData () {
    return (
        <>
            <div class="flex flex-col space-y-20 ...">
                <Head title="Market Data" />
                <Header />
                <MainHeader />
            </div>
            <div class="relative z-10">
                <MarketDataTabs />
            </div>
            <MainContent />
            <Footer />

        </>
    );
}

