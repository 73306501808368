
export default function MainHeader() {
    return (

        <div className="bg-white">
            <div className="container flex flex-col relative py-6 px-4 mx-auto items-center lg:py-16 z-10 relative">
                <div className="w-11/12 sm:w-2/3 lg:flex justify-center items-center flex-col ">
                    <div className="hidden sm:mb-5 sm:flex sm:justify-center">
                        <a href="market-data" class="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-black bg-green-100 rounded-full dark:bg-green-900 dark:text-green-300 hover:bg-green-200 dark:hover:bg-green-800">
                            <span class="text-xs bg-red-600 rounded-full text-white px-4 py-1.5 mr-3">Live Data</span> <span class="text-sm font-medium">View live free market data by clicking here</span>
                            <svg aria-hidden="true" class="ml-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                        </a>
                    </div>
                    <h1 class="text-4xl font-bold tracking-tight text-center text-gray-900 sm:text-6xl">Find  <span className="text-green-700"> Out More </span>
                        News.</h1><br />
                    <p class="mb-8 text-lg font-normal text-gray-600 lg:text-xl text-center sm:px-16 lg:px-48 dark:text-gray-200 dark:text-gray-700">ZAMACE facilitates various events for our members to participate and engage with various stakeholders</p>
                   
                </div>
                <div className="flex justify-center items-center">
                    <div className="mt-6 flex items-center justify-center gap-x-6">

                    </div>
                </div>
            </div>
            <div class="bg-gradient-to-b from-green-50 to-transparent w-full h-full absolute top-0 left-0 z-0"></div>
        </div>



    );
}

